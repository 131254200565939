import React from 'react';

const ExternalLink = ({
  underline, href, children, ...rest
}) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={href}
    {...rest}
    className={`${underline && 'underline-gradient'}`}
		>
    {children ?? href}
  </a>
);

export default ExternalLink;

ExternalLink.defaultProps = {
  underline: true,
};
