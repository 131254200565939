import React from 'react';
import Lottie from 'react-lottie';
import Layout from '../components/layout';
import ExternalLink from '../components/externalLink';
import * as animationData from '../lotties/anim.json';

const Uses = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Layout>
      <div className="container max-w-3xl px-4 text-xl leading-relaxed text-gray-500 bg-white dark:bg-cloud-700 dark:text-gray-300 md:px-0">
        <section className="py-12 ">
          <h1 className="mb-2 text-6xl text-gradient ">Uses</h1>
          <p>
            On this page you can find everything I use as a
            developer. This page is inspired by Wes Bos'
            {' '}
            <ExternalLink href="https://uses.tech/">
              uses.tech
            </ExternalLink>
            {' '}
            where you can see what other developers are using.
          </p>
          <div className="my-6">
            <Lottie
              options={defaultOptions}
              height={400}
              width={400}
            />
          </div>

          <br />
          <h2 className="mb-3 text-3xl text-gray-800 dark:text-gray-100">
            Code Editor
          </h2>
          <ul className="list-disc list-inside">
            <li>
              <ExternalLink href="https://code.visualstudio.com/">
                <b>Visual Studio Code</b>
              </ExternalLink>
              {' '}
            </li>
            <li>
              <b>Fonts:</b>
              {' '}
              <ExternalLink href="https://www.marksimonson.com/fonts/view/anonymous-pro">
                Anonymous Pro
              </ExternalLink>
              {' '}
              ,
              {' '}
              <ExternalLink href="https://dank.sh">
                Dank Mono
              </ExternalLink>
              {' '}
              ,
              <ExternalLink href="https://www.jetbrains.com/lp/mono/">
                JetBrains Mono
              </ExternalLink>
              {' '}
              ,
              <ExternalLink href="https://fonts.google.com/specimen/Space+Mono">
                Space Mono
              </ExternalLink>
            </li>
            <li>
              <b>Themes:</b>
              {' '}
              <ExternalLink href="https://marketplace.visualstudio.com/items?itemName=juliettepretot.lucy-vscode">
                Lucy
              </ExternalLink>
              ,
              {' '}
              <ExternalLink href="https://marketplace.visualstudio.com/items?itemName=tinkertrain.theme-panda">
                Panda
              </ExternalLink>
              ,
              {' '}
              <ExternalLink href="https://marketplace.visualstudio.com/items?itemName=akamud.vscode-theme-onedark">
                Atom One Dark
              </ExternalLink>
              ,
              {' '}
              <ExternalLink href="https://marketplace.visualstudio.com/items?itemName=akamud.vscode-theme-onelight">
                Atom One Light
              </ExternalLink>
            </li>
            <li>
              <b>Extensions:</b>
              {' '}
              <ExternalLink href="https://marketplace.visualstudio.com/items?itemName=esbenp.prettier-vscode">
                Prettier
              </ExternalLink>
              ,
              {' '}
              <ExternalLink href="https://marketplace.visualstudio.com/items?itemName=bradlc.vscode-tailwindcss">
                Tailwind Css intellisense
              </ExternalLink>
              ,
              {' '}
              <ExternalLink href="https://marketplace.visualstudio.com/items?itemName=dsznajder.es7-react-js-snippets">
                ES7 React/Redux/GraphQL/React-Native snippets
              </ExternalLink>
            </li>
            <li>
              <b>Product icons: </b>
              <ExternalLink href="https://marketplace.visualstudio.com/items?itemName=antfu.icons-carbon">
                Carbon product Icons
              </ExternalLink>
            </li>
          </ul>
          <br />
          <h2 className="mb-3 text-3xl text-gray-800 dark:text-gray-100">
            Software
          </h2>
          <ul className="list-disc list-inside">
            <li>
              <b>Browser:</b>
              {' '}
              Firefox , Vivaldi
            </li>
            <li>
              <b>Git Client:</b>
              {' '}
              <ExternalLink href="https://www.sublimemerge.com">
                Sublime Merge
              </ExternalLink>
            </li>
          </ul>
          <br />
          <h2 className="mb-3 text-3xl text-gray-800 dark:text-gray-100">
            Operating System
          </h2>
          <ul className="list-disc list-inside">
            <li>
              <ExternalLink href="https://pop.system76.com/">
                <b>Pop!_OS</b>
              </ExternalLink>
              {' '}
            </li>
            <li>
              <b>Desktop environment:</b>
              {' '}
              Gnome
              {' '}
            </li>
            <li>
              <b>Icon theme:</b>
              {' '}
              <ExternalLink href="https://github.com/heychrisd/Boston-Icons">
                Boston
              </ExternalLink>
            </li>
          </ul>
          <br />
          <h2 className="mb-3 text-3xl text-gray-800 dark:text-gray-100">
            Hardware
          </h2>
          <ul className="list-disc list-inside">
            <li>
              HP Pavilion Gaming 15.6-inch FHD Gaming Laptop
              (Ryzen 5-4600H/8GB/1TB HDD + 256GB SSD/144Hz/NVIDIA
              GTX 1650 4GB/Shadow Black), 15-ec1052AX
            </li>
            <li>Oneplus 7 </li>
            <li>Galaxy Tab A7</li>
            <li>
              BenQ 54.6 (21.5-inch) LED Backlit Computer Monitor,
              GW2283 (Black)
            </li>
          </ul>
        </section>
      </div>
    </Layout>
  );
};

export default Uses;
